import { Button, Center } from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { TbArrowBackUp } from "react-icons/tb";

const FormattedMessage = ({ message }: { message: string }) => {
  return (
    <div>
      {message.split("\n").map((line, index) => (
        <div key={index}>{line}</div>
      ))}
    </div>
  );
};

export const showErrorToast = (
  message: string,
  position:
    | "top-right"
    | "bottom-right"
    | "top-left"
    | "bottom-left" = "bottom-right",
) => {
  notifications.show({
    message: <FormattedMessage message={message} />,
    color: "red",
    position,
  });
};

export const showSuccessToast = (
  message: string,
  position:
    | "top-right"
    | "bottom-right"
    | "top-left"
    | "bottom-left" = "bottom-right",
) => {
  notifications.show({
    message: <FormattedMessage message={message} />,
    color: "green",
    position,
  });
};

export const showUndoToast = (
  message: string | React.ReactNode,
  undoCallback: () => void = () => {},
) => {
  const notifId = notifications.show({
    message: (
      <Center className="flex flex-row justify-between">
        {message}
        <Button
          className="text-primary-700"
          variant="transparent"
          onClick={undoClicked}
        >
          <TbArrowBackUp color="green" />
          &nbsp;Undo
        </Button>
      </Center>
    ),
    color: "green",
  });

  function undoClicked() {
    notifications.hide(notifId);
    undoCallback();
  }
};
